import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PWAPrompt from "react-ios-pwa-prompt";

import "./App.css";

import "bootstrap/dist/css/bootstrap.css";
import "video-react/dist/video-react.css"; // import css

// Page content
import NavigationBar from "./components/NavigationBar";
import HomePage from "./components/HomePage";
import BioPage from "./components/BioPage";
import ContactPage from "./components/ContactPage";
import MediaPage from "./components/MediaPage";
import SongsPage from "./components/SongsPage";
import LessonsPage from "./components/LessonsPage";
import CustomPage from "./components/CustomPage";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Posts from "./components/posts/Posts";
import Post from "./components/post/Post";
import Event from "./components/event/Event";
import NotFound from "./components/NotFound";

//Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <NavigationBar />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/dorothy-ophals-bio" component={Bio} />
          <Route path="/contact-dorothy-ophals" component={Contact} />
          <Route path="/image-and-video-gallery" component={Media} />
          <Route path="/songs-and-albums" component={Songs} />
          <Route path="/music-lessons" component={Lessons} />
          <Route path="/custom-songs-for-special-events" component={Custom} />

          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/posts" component={Posts} />
          <Route exact path="/posts/:id" component={Post} />
          <Route exact path="/events/:id" component={Event} />
          <Route component={NotFound} />
        </Switch>
        <PWAPrompt
          promptOnVisit={2}
          timesToShow={2}
          copyClosePrompt="Dismiss"
          permanentlyHideOnDismiss={false}
        />
      </Router>
    </Provider>
  );
};

function Home() {
  return <HomePage />;
}

function Bio() {
  return <BioPage />;
}

function Contact() {
  return <ContactPage />;
}

function Media() {
  return <MediaPage />;
}

function Songs() {
  return <SongsPage />;
}

function Lessons() {
  return <LessonsPage />;
}

function Custom() {
  return <CustomPage />;
}

export default App;
