import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Player } from 'video-react';

// Images paths
import mainPoster from '../../resources/images/dorothy-ophals-playing-piano.jpg';
import longLogo from '../../resources/images/dorothy-ophals-welcome-logo.png';

const MainVideo = () => {
    return (
        <section>
            <Row style={{ marginBottom: 50 }}>
                <Col className='timeline-intro' sm={12}>
                    <img
                        src={longLogo}
                        style={{ maxWidth: 700 }}
                        alt='Dorothy Ophals long logo'
                    />
                    <h2>Create In Me A Clean Heart</h2>
                    <h5>Live in the Studio</h5>
                </Col>
            </Row>
            <Row className='home-hero-video'>
                <Col>
                    <Player
                        poster={mainPoster}
                        playsInline
                        src='https://uploads.dorothyophals.com/uploads/create-in-me-a-clean-heart.mp4'
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: 50 }}>
                <Col className='video-credits' sm={12}>
                    <h3>Video Credits</h3>
                    <ul>
                        <li>
                            <b>Words ~ Music:</b> Dorothy Jude Ophals
                        </li>
                        <li>
                            <b>Special Thanks:</b>
                            <br />
                            Barbara Feltkamp - Cello • Brontte Hwang - Violin •
                            Christina Mercurio • Cello.
                            <br />
                            <br />
                            <p>
                                It's truly a joy to worship with you ladies.
                                Thank you my friends!
                            </p>
                        </li>
                        <li>
                            <b>Recorded and Produced by:</b> Chris Pati@Indi
                            Music Studios
                        </li>
                        <li>
                            <b>Videographer:</b> Cameron Prezzano
                        </li>
                        <li>
                            <b>Rich My Love,</b> Thank you for encouraging me in
                            the gift God has given me.
                        </li>
                        <li>
                            <b>Thank you Jesus</b> for loving me, forgiving me
                            and cleansing me. Thank you for my new heart!
                        </li>
                        <li>
                            With Love and Appreciation to all of you!
                            <br />
                            <b>Dorothy</b>
                        </li>
                    </ul>
                </Col>
            </Row>
        </section>
    );
};

export default MainVideo;
