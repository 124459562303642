import React from "react";
import { Row, Col } from "react-bootstrap";

class ScrollToTop extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

class LessonsMain extends React.Component {
  render() {
    return (
      <section>
        <ScrollToTop />
        <Row>
          <Col className="cs-first-column" sm={12}>
            <h2>Personalized Lessons</h2>
            <h1>Spreading The Talents</h1>
          </Col>
        </Row>
      </section>
    );
  }
}

export default LessonsMain;
