import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';

import dorothyRich from '../../resources/images/dorothy-and-rich-ophals.jpg';

class ScrollToTop extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

class BioHistory extends React.Component {
  render() {
    return (
      <section>
        <ScrollToTop />
        <Row>
          <Col className='cs-first-column' sm={12}>
            <h2>My History</h2>
            <h3>A quick Note from Me to you the reader</h3>
          </Col>
        </Row>
        <Row>
          <Col className='the-family' lg={6} md={6} sm={12}>
            <p>I come from a family of Musicians:</p>
            <ListGroup>
              <ListGroup.Item>
                My Brother Bobby Rondinelli who is an amazing drummer, arranger.
              </ListGroup.Item>
              <ListGroup.Item>
                My Brother Teddy Rondinelli who is an amazing guitar player /
                singer / songwriter
              </ListGroup.Item>
              <ListGroup.Item>
                My Father Robert R. Rondinelli was a truly gifted / poet /
                singer/ songwriter
              </ListGroup.Item>
              <ListGroup.Item>
                My Mom could sing too- but her gift was cooking and loving us
                all Well.
              </ListGroup.Item>
            </ListGroup>
            <p style={{ marginTop: 20, marginBottom: 40 }}>
              My family has been an inspiration for me and Im forever grateful.
            </p>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div className='box-highlight'>
              <img src={dorothyRich} alt='Dorothy and Rich Ophals' />
              <p className='richie'>
                All the songs I write go past my husband Rich; though he is not
                a musician, he is truly a lover of music and has an incredible
                ear for great music and what touch’s the soul. Thank you honey
                for your encouragement, advice and love over the last 25 years.
                You inspire me, I Love You!
              </p>
            </div>
          </Col>
        </Row>
      </section>
    );
  }
}

export default BioHistory;
