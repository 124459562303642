import React from 'react';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { Row, Col } from 'react-bootstrap';

// Images
import longLogo from '../../resources/images/dorothy-ophals-welcome-logo.png';

class HomeTimeline extends React.Component {
  render() {
    return (
      <section>
        <Row>
          <Col className='timeline-intro' sm={12}>
            <img
              src={longLogo}
              style={{ maxWidth: 700 }}
              alt='Dorothy Ophals long logo'
            />
            <p>
              Follow Dorothy's work and all what she has accomplished over the
              years.
            </p>
          </Col>
        </Row>

        <Timeline lineColor={'#000'}>
          <TimelineItem key='001' dateText='2002' style={{ color: '#B79A20' }}>
            <h3>
              Published song with
              <br />
              Kathy Troccoli
            </h3>
            <h4>Album: The Heart of Me</h4>
            <p>
              Song : You’re Still God
              <br />
              Performed at Smithtown Gospel Tabernacle concert with Kathy
              Troccoli singing song we composed together Song on Grammy
              nominated album “ The Heart of me”
            </p>
          </TimelineItem>
          <TimelineItem key='002' dateText='2003' style={{ color: '#B79A20' }}>
            <h3>Dominican Republic</h3>
            <h4>Missions trip</h4>
            <p>
              Worship director for crusade / conducted choir for Missions trip
              Estadio de Beisbol 20,000 believers came to worship all the way
              from D.R. and Hatti
            </p>
          </TimelineItem>
          <TimelineItem key='003' dateText='2004' style={{ color: '#B79A20' }}>
            <h3>Dominican Republic</h3>
            <h4>Second Missions trip</h4>
            <p>
              * Missions Trip to Dominican Republic / Conducted a 40 piece choir
              in Spanish Worship director for Crusade
              <br />
              * Freedom Chapel worship team/ Youth Group worship trainer
              <br />* Recorded on Freedom Chapel CD
            </p>
          </TimelineItem>
          <TimelineItem key='004' dateText='2005' style={{ color: '#B79A20' }}>
            <h3>Cuba</h3>
            <h4>Missions trip</h4>
            <p>
              Missions trip to Cuba / Lead worship for Crusade with Freedom
              Chapel worship center
              <br />
              * Worship team/ Freedom Chapel <br />
              * Youth team / trained worship team <br />* Worship team / Worship
              at Celebrate Teen Challenge weekend PA / With Pastor David
              Wilkerson
            </p>
          </TimelineItem>
          <TimelineItem key='005' dateText='2006' style={{ color: '#B79A20' }}>
            <h3>Portugal</h3>
            <h4>Missions trip</h4>
            <p>
              Freedom Chapel worship team / youth group training
              <br />
              Recorded on Freedom Chapel CD
            </p>
          </TimelineItem>
          <TimelineItem key='006' dateText='2007' style={{ color: '#B79A20' }}>
            <p>
              * Wrote and directed “ We Crown Him King” a 2 hour Christmas play
              / from Genisis to the Birth of Christ / also composed all the
              songs . <br />* Piano / Percussion worship duo “ His Remnant” with
              Delia Gonzalez / concert at Jones Beach band shell and local
              church’s
            </p>
          </TimelineItem>
          <TimelineItem key='007' dateText='2008' style={{ color: '#B79A20' }}>
            <p>
              * Worship team for Freedom Chapel home church
              <br />
              * Worship trainer for Grace church in Babylon <br />
              Wrote and directed children Christmas Play / songs
              <br />
              Music teacher for Babylon Christian school for 1 year
              <br />* Worship leader for Faith Tabernacle West Babylon NY (for
              there weekly Bible Study for 1 year).
            </p>
          </TimelineItem>
          <TimelineItem key='008' dateText='2009' style={{ color: '#B79A20' }}>
            <p>
              * Smithtown Gospel Tabernacle Worship leader for Ancient Paths
              ministry
              <br />* Piano teacher
            </p>
          </TimelineItem>
          <TimelineItem key='009' dateText='2010' style={{ color: '#B79A20' }}>
            <h3>Ancient Paths ministry</h3>
            <h4>Worship leader</h4>
            <p>
              * Couple retreat’s
              <br />
              * Womans retreat Tuscarora Pennsylvania
              <br />* Worship at numerous Small church throughout Long Island ,
              NY
            </p>
          </TimelineItem>
          <TimelineItem key='010' dateText='2011' style={{ color: '#B79A20' }}>
            <p>
              Worship leader Ancient Paths ministry/ Couple retreats / Womans
              retreat Tuscarora Pennsylvania
            </p>
          </TimelineItem>
          <TimelineItem key='011' dateText='2012' style={{ color: '#B79A20' }}>
            <p>
              * Worship leader Ancient Paths ministry/ Couple retreat / Womans
              retreat Tuscarora Pennsylvania * Hired to write wedding songs
              <br />
              * Working on SongWriting / In studio recording
              <br />
              * Teaching Piano / Guitar/ Songwriting
              <br />* Trained “THE KEY” a team of 12 young men and woman once a
              month on Worship at my home for 2 years learning to worship
              together with there voice and instruments
            </p>
          </TimelineItem>
          <TimelineItem key='012' dateText='2013' style={{ color: '#B79A20' }}>
            <h3>Worship leader</h3>
            <p>
              Ancient Paths ministry / Couple retreat / Womans retreat Tuscarora
              Pennsylvania
            </p>
          </TimelineItem>
          <TimelineItem key='013' dateText='2014' style={{ color: '#B79A20' }}>
            <p>
              * Worship leader/ Smithtown Gospel Tabernacle Couples Retreat in
              Mystic Connecticut
              <br />* For one year Trained a young worship team in Queens NY
              Ingelsis, raised money to purchase musical equipment for these
              amazing boys and girls.
            </p>
          </TimelineItem>
          <TimelineItem key='014' dateText='2015' style={{ color: '#B79A20' }}>
            <h3>Ojebwe Tribe Reservation</h3>
            <h4>Missions trip to Minnesota</h4>
            <p>
              * Facilitated Ancient Paths Seminar ( Family Foundations ministry)
              with my husband on a Indian Reservation .<br />
              *Lead Worship with Ojebwe tribe leaders.
              <br />
              * Womans Christmas Tea Smithtown Gospel Tabernacle / worship
              <br />
              * Smithtown Gospel Tabernacle NY Womans retreats - worship leader
              <br />
              Smithtown Gospel Tabernacle NY Couple’s Retreats
            </p>
          </TimelineItem>
          <TimelineItem key='015' dateText='2016' style={{ color: '#B79A20' }}>
            <p>
              * Became the Children’s Choir director for Smithtown Gospel
              Tabernacle for “Joyful Singers’ 60 -70 kids from ages 6-12 They
              sing for: * Mothers Day/ Fathers Day/ Mission Convention/They sing
              for 6 presentations The Glory of Christmas
              <br />
              * Lead worship for Married for Life / facilitated group with my
              husband in our home for 3 months
              <br />
              * Guest Speaker/ Concert at Open Gates ministry in Long Island NY
              <br />* Writing and recording songs for the children”s choir
            </p>
          </TimelineItem>
          <TimelineItem key='016' dateText='2017' style={{ color: '#B79A20' }}>
            <h3>Choir director for Smithtown Gospel Tabernacle</h3>
            <h4>Children’s Choir “Joyful Singers “</h4>
            <p>
              Mothers Day/ Fathers Day/ Mission Convention/ The Glory of
              Christmas
            </p>
            <h3>Canada Missions Trip</h3>
            <h4>Leading Ancient Paths and Worship</h4>
          </TimelineItem>
          <TimelineItem key='017' dateText='2018' style={{ color: '#B79A20' }}>
            <p>
              * Worship leader for Womans Retreat Tuscarora Pennsylvania
              <br />
              * Ancient Paths worship Leader
              <br />
              * Co- Founder of “Radiant” Womans Fellowship (leadership with
              Veronica Alvero and Megan Pleitez).
              <br />
              Monthly fellowship with 20 amazing ladies/ Worship Leader / Mentor
              / Friend
              <br />* Radiant Woman’s Fellowship 4 day Get-away in East Hampton
              NY / Co-organizer / Worship Leader
            </p>
          </TimelineItem>
          <TimelineItem key='018' dateText='2019' style={{ color: '#B79A20' }}>
            <h3>“ENTER & DWELL“</h3>
            <h4>First Radiant Conference</h4>
            <p>At Christian Community Church Farmingdale NY • Lead Worship</p>
            <h3>"Believe” Book and CD Recordings</h3>
            <h4>My new Adventure</h4>
            <p>
              I’m in the process of writing my first book “Believe”, at the same
              time, I'm recording 2 new CD's that will be RELEASED IN 2020.
            </p>
          </TimelineItem>
        </Timeline>
      </section>
    );
  }
}

export default HomeTimeline;
