import React from "react";
import { FadeTransform } from "react-animation-components";
import SpinnerLoader from "./SpinerLoader";

// Parts of LessonsPage
import Footer from "./Footer";
import LessonsMain from "./lessons/LessonsMain";
import LessonsDetails from "./lessons/LessonsDetails"

class LessonsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  }

  render() {
    let contentLessons = this.state.loading ? (
      <SpinnerLoader />
    ) : (
      <FadeTransform
        in
        transformProps={{
          exitTransform: "translateY(-50px)"
        }}
        fadeProps={{
          enterOpacity: 1
        }}
      >
       	<LessonsMain />
      	<LessonsDetails /> 
        <Footer />
      </FadeTransform>
    );

    return <div>{contentLessons}</div>;
  }
}

export default LessonsPage;