import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import lessonsOne from '../../resources/images/guitar-lessons-by-dorothy-ophals.jpg';
import lessonsTwo from '../../resources/images/songwriting-lessons-by-dorothy-ophals.jpg';
import lessonsThree from '../../resources/images/piano-lessons-by-dorothy-ophals.jpg';
import worshipping from '../../resources/images/worshipping-training.jpg';

class LessonsDetails extends React.Component {
  render() {
    return (
      <section>
        <Row>
          <Col className='lessons-for-all' lg='4' md='4' sm='12'>
            <img src={lessonsOne} alt='Guitar Lessons by Dorothy Ophals' />
            <h3>Guitar Lessons</h3>
          </Col>
          <Col className='lessons-for-all' lg='4' md='4' sm='12'>
            <img src={lessonsTwo} alt='Guitar Lessons by Dorothy Ophals' />
            <h3>Songwriting</h3>
          </Col>
          <Col className='lessons-for-all' lg='4' md='4' sm='12'>
            <img src={lessonsThree} alt='Guitar Lessons by Dorothy Ophals' />
            <h3>Piano Lessons</h3>
          </Col>
        </Row>
        <Row>
          <Col className='worship-section' lg={12}>
            <img
              className='lessons-worship'
              src={worshipping}
              alt='Guitar Lessons by Dorothy Ophals'
            />
            <h4>
              Worship
              <br />
              <span>Training</span>
            </h4>
            <p>Use your talents to give honor and glory to our Lord</p>
          </Col>
          <Col className='contact-me' lg={12}>
            <Button className='contact-me-b' variant='warning'>
              <Link className='contact-me-btn' to='/contact-dorothy-ophals'>
                Contact Me Now To Start
              </Link>
            </Button>
          </Col>
        </Row>
      </section>
    );
  }
}

export default LessonsDetails;
