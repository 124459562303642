import React from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';

import 'react-image-gallery/styles/css/image-gallery.css';

// Images
import joyfulvOne from '../../resources/gallery/joyfull-singers-misc-01.JPG';
import joyfulvTwo from '../../resources/gallery/joyfull-singers-misc-02.JPG';
import joyfulvThree from '../../resources/gallery/joyfull-singers-misc-03.PNG';
import blessingOne from '../../resources/gallery/joyful-singer-blessing-one.jpg';
import blessingTwo from '../../resources/gallery/joyful-singer-blessing-two.jpg';
import blessingThree from '../../resources/gallery/joyful-singer-blessing-three.jpg';

class MediaGallery extends React.Component {
  render() {
    return (
      <section>
        <div className='gallery-subtitles'>
          <h2>More Pictures</h2>
        </div>
        <Row className='second-gallery'>
          <Col className='second-gallery-1' lg={4} md={12} sm={12}>
            <Carousel>
              <Carousel.Item>
                <img
                  className='d-block w-100'
                  src={joyfulvTwo}
                  alt='Third slide'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100'
                  src={joyfulvThree}
                  alt='Third slide'
                />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col className='second-gallery-2' lg={2} md={6} sm={6}>
            <img
              className='blessings-images'
              src={joyfulvOne}
              alt='First slide'
            />
          </Col>
          <Col className='second-gallery-2' lg={2} md={6} sm={6}>
            <img
              className='blessings-images'
              src={blessingOne}
              alt='Dorothy imparting blessing'
            />
            <h4 className='video-description'>
              You Are
              <br /> A Warrior
            </h4>
          </Col>
          <Col className='second-gallery-2' lg={2} md={6} sm={6}>
            <img
              className='blessings-images'
              src={blessingTwo}
              alt='Dorothy imparting blessing'
            />
            <h4 className='video-description'>
              Mighty Man
              <br /> of God
            </h4>
          </Col>
          <Col className='second-gallery-2' lg={2} md={6} sm={6}>
            <img
              className='blessings-images'
              src={blessingThree}
              alt='Dorothy imparting blessing'
            />
            <h4 className='video-description'>
              God's
              <br /> Princess
            </h4>
          </Col>
        </Row>
      </section>
    );
  }
}

export default MediaGallery;
