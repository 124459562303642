import React from "react";
import { FadeTransform } from "react-animation-components";
import SpinnerLoader from "./SpinerLoader";

// Parts of BioPage
import Footer from "./Footer";
import ContactMain from "./contacts/ContactMain";
import ContactInfo from "./contacts/ContactInfo";
import ContactMap from "./contacts/ContactMap";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  }

  render() {
    let contentContact = this.state.loading ? (
      <SpinnerLoader />
    ) : (
      <FadeTransform
        in
        transformProps={{
          exitTransform: "translateY(-50px)"
        }}
        fadeProps={{
          enterOpacity: 1
        }}
      >
        <ContactMain />
        <ContactMap />
        <ContactInfo />
        <Footer />
      </FadeTransform>
    );

    return <div>{contentContact}</div>;
  }
}

export default ContactPage;
