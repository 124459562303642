import React, { useEffect, useState } from 'react';
import { FadeTransform } from 'react-animation-components';
import SpinnerLoader from './SpinerLoader';

// Parts of SongsPage
import Footer from './Footer';
import MediaMain from './songs/MediaMain';
import TheAlbum from './songs/TheAlbum';

import CoverOne from '../resources/images/its-all-about-love.jpg';
import CoverTwo from '../resources/images/dorothy-ophals-live.jpg';
import CoverThree from '../resources/images/i-want-to-know-you.jpg';
import CoverFour from '../resources/images/nothing-like-your-love.jpg';
import CoverFive from '../resources/images/angels-sing.jpg';
import CoverSix from '../resources/images/worship-album.jpg';

const SongsPage = () => {
    const [loading, setLoading] = useState(true);
    const delay = 15;

    useEffect(() => {
        let timer1 = setTimeout(() => setLoading(false), delay * 100);

        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        // and show will not change to true
        return () => {
            clearTimeout(timer1);
        };
    }, []);

    return loading ? (
        <SpinnerLoader />
    ) : (
        <FadeTransform
            in
            transformProps={{
                exitTransform: 'translateY(-50px)',
            }}
            fadeProps={{
                enterOpacity: 1,
            }}
        >
            <section>
                <MediaMain />
                <TheAlbum info={albumoneinfo} songs={albumone} />
                <TheAlbum info={albumtwoinfo} songs={albumtwo} />
                <TheAlbum info={albumthreeinfo} songs={albumthree} />
                <TheAlbum info={albumfourinfo} songs={albumfour} />
                <TheAlbum info={albumfiveinfo} songs={albumfive} />
                <TheAlbum info={albumsixinfo} songs={albumsix} />
            </section>
            <Footer />
        </FadeTransform>
    );
};

const albumoneinfo = {
    title: `It's All About Love`,
    image: CoverOne,
};
const albumtwoinfo = {
    title: `Dorothy Ophals Live`,
    image: CoverTwo,
};
const albumthreeinfo = {
    title: `I Want To Now You`,
    image: CoverThree,
};
const albumfourinfo = {
    title: `Nothing Like Your Love`,
    image: CoverFour,
};
const albumfiveinfo = {
    title: `Angels Sing`,
    image: CoverFive,
};
const albumsixinfo = {
    title: `Coming Soon - Out Of The Fire`,
    image: CoverSix,
};
const albumone = [
    {
        id: 1,
        localclip: '/audio/this-ring-duet.mp3',
        songname: 'This Ring',
        apple:
            'https://music.apple.com/us/album/this-ring/1558918360?i=1558918361',
        spotify:
            'https://open.spotify.com/track/5K37rWdMBKRD06oM31RQLu?si=917ab2a5778a411d',
    },
    {
        id: 2,
        localclip: '/audio/believe-by-dorothy-ophals-2019.mp3',
        songname: 'Believe',
        apple:
            'https://music.apple.com/us/album/believe/1558918360?i=1558918362',
        spotify:
            'https://open.spotify.com/track/3v0EOmQBCARELIGWeFeJH5?si=5c7c0d678df443c0',
    },
    {
        id: 3,
        localclip: '/audio/eres-mi-amor.mp3',
        songname: 'Eres Mi Amor',
        apple:
            'https://music.apple.com/us/album/eres-mi-amor/1558918360?i=1558918363',
        spotify:
            'https://open.spotify.com/track/409HFYkBq2edb73yYQsCR0?si=a481bea742f24fac',
    },
    {
        id: 4,
        localclip: '/audio/i-love-you-more.mp3',
        songname: 'I Love You More',
        apple:
            'https://music.apple.com/us/album/i-love-you-more/1558918360?i=1558918364',
        spotify:
            'https://open.spotify.com/track/1zU1s0qO7oq8FMLVDA9eb8?si=db838a15b2bb4e25',
    },
];
const albumtwo = [
    {
        id: 1,
        localclip: '/audio/enter-and-dwell.mp3',
        songname: 'Enter And Dwell',
        apple:
            'https://music.apple.com/us/album/enter-and-dwell-live/1558894461?i=1558894465',
        spotify:
            'https://open.spotify.com/track/0LUCJR5qggDMWF8hkscLlS?si=c99721b1018c4600',
    },
    {
        id: 2,
        localclip: '/audio/you-are-getting-closer.mp3',
        songname: `You're Getting Closer`,
        apple:
            'https://music.apple.com/us/album/youre-getting-closer-live/1558894461?i=1558894467',
        spotify:
            'https://open.spotify.com/track/5aDet3Vorb3y5G9qQFzfMn?si=a9f7e969873a488e',
    },
    {
        id: 3,
        localclip: '/audio/create-in-me-a-clean-heart.mp3',
        songname: 'Create In Me A Clean Heart - Live',
        apple:
            'https://music.apple.com/us/album/create-in-me-a-clean-heart-live/1558894461?i=1558894468',
        spotify:
            'https://open.spotify.com/track/7BAHHR3VhNK0fpIzdt5Vsc?si=10e17225afa74940',
    },
];
const albumthree = [
    {
        id: 1,
        localclip: '/audio/i-want-to-know-you.mp3',
        songname: 'I Want To Know You',
        apple:
            'https://music.apple.com/us/album/i-want-to-know-you/1558917344?i=1558917345',
        spotify:
            'https://open.spotify.com/track/29B9iDZmpsQUvGAfyhw2t3?si=81a7da8c35aa4f94',
    },
    {
        id: 2,
        localclip: '/audio/this-is-what-i-pray.mp3',
        songname: `This Is What I Pray`,
        apple:
            'https://music.apple.com/us/album/this-is-what-i-pray/1558917344?i=1558917346',
        spotify:
            'https://open.spotify.com/track/0pBQPYZFrG4XLRyLOFyw2n?si=190c6d9f0d05446c',
    },
    {
        id: 3,
        localclip: '/audio/i-did-it-all-for-you.mp3',
        songname: 'I Did It All For You',
        apple:
            'https://music.apple.com/us/album/i-did-it-all-for-you/1558917344?i=1558917347',
        spotify:
            'https://open.spotify.com/track/5BE7WbSfn0r8lnPzO975QC?si=58b7ef46e02d4656',
    },
];
const albumfour = [
    {
        id: 1,
        localclip: '/audio/nothing-like-your-love.mp3',
        songname: 'Nothing Like Your Love',
        apple:
            'https://music.apple.com/us/album/nothing-like-your-love/1558915488?i=1558915491',
        spotify:
            'https://open.spotify.com/track/45hOlh5KbPcb4r0FGUFEzw?si=59da349574d245e8',
    },
];
const albumfive = [
    {
        id: 1,
        localclip: '/audio/01-angels-sing.mp3',
        songname: 'Angels Sing',
        apple:
            'https://music.apple.com/us/album/angels-sing/1558199693?i=1558199696',
        spotify:
            'https://open.spotify.com/track/04vZeqOKRdOjwezKCQCato?si=5fa7e4f540e94625',
    },
    {
        id: 2,
        localclip: '/audio/02-i-count-my-blessings.mp3',
        songname: 'I Count My Blessings',
        apple:
            'https://music.apple.com/us/album/i-count-my-blessings/1558199693?i=1558199698',
        spotify:
            'https://open.spotify.com/track/6NTYk28NFc0qlMKBJ91NdY?si=beac8b6943144438',
    },
    {
        id: 3,
        localclip: '/audio/03-i-do.mp3',
        songname: 'I Do',
        apple: 'https://music.apple.com/us/album/i-do/1558199693?i=1558199700',
        spotify:
            'https://open.spotify.com/track/3hWODEANv7MhBw5RpSjemc?si=91c6cd0c32c0416a',
    },
    {
        id: 4,
        localclip: '/audio/04-get-right-with-the-lord.mp3',
        songname: 'Get Right With The Lord',
        apple:
            'https://music.apple.com/us/album/get-right-with-the-lord/1558199693?i=1558199702',
        spotify:
            'https://open.spotify.com/track/4YVCt63eJxmsNYU8e3QQO5?si=a7bdd0f44da14d5c',
    },
    {
        id: 5,
        localclip: '/audio/05-i-wanna-see-you-there.mp3',
        songname: 'I Wanna See You There',
        apple:
            'https://music.apple.com/us/album/i-wanna-see-you-there/1558199693?i=1558199704',
        spotify:
            'https://open.spotify.com/track/3bV3HJD808K3dcWdPIeo9C?si=bc93305d5e994e95',
    },
    {
        id: 6,
        localclip: '/audio/07-in-the-garden.mp3',
        songname: 'In The Garden',
        apple:
            'https://music.apple.com/us/album/in-the-garden/1558199693?i=1558200036',
        spotify:
            'https://open.spotify.com/track/78XTk9Yfzv01lz6GlkfFpl?si=43f6fb18a7c04df3',
    },
    {
        id: 7,
        localclip: '/audio/08-anastasia.mp3',
        songname: 'Anastasia',
        apple:
            'https://music.apple.com/us/album/anastasia/1558199693?i=1558200038',
        spotify:
            'https://open.spotify.com/track/4Url9T1W2qegWh8RX9DCAY?si=1d0c7eb9d8f542d6',
    },
];
const albumsix = [
    {
        id: 1,
        localclip: '',
        songname: 'Coming This Fall',
        apple: '#',
        spotify: '#',
    },
];

export default SongsPage;
