import React from "react";
import axios from "axios";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class ContactForm extends React.Component {
  state = {
    name: "",
    email: "",
    phone: "",
    eventtype: "",
    thedate: new Date(),
    address: "",
    details: "",
    mailSent: false,
    mailSending: false,
    mailError: false
  };

  formSubmit = e => {
    e.preventDefault();

    this.setState({
      mailSending: "...we are sending"
    });

    let data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      eventtype: this.state.eventtype,
      thedate: this.state.thedate,
      address: this.state.address,
      details: this.state.details
    };

    axios
      .post("/api/emails", data)
      .then(res => {
        this.setState({ sent: true }, this.resetForm());
      })
      .catch(() => {
        console.log("Message not sent");
      });
  };

  resetForm = () => {
    this.setState({
      name: "",
      email: "",
      phone: "",
      eventtype: "",
      thedate: "",
      address: "",
      details: "",
      mailSent: "Message Sent"
    });
  };
  render() {
    return (
      <form onSubmit={e => this.formSubmit(e)}>
        <div className="row-field row">
          <div className="col-field col-sm-12 col-md-12 row">
            <div className="form-group col-sm-6 col-md-6">
              <input
                type="text"
                className="form-control"
                name="name"
                required
                placeholder="Name *"
                onChange={e => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="form-group col-sm-6 col-md-6">
              <input
                type="email"
                className="form-control"
                name="email"
                required
                placeholder="Email *"
                onChange={e => this.setState({ email: e.target.value })}
              />
            </div>
          </div>
          <div className="col-field col-sm-12 col-md-12 row">
            <div className="form-group col-sm-6 col-md-6">
              <input
                type="tel"
                className="form-control"
                name="phone"
                required
                placeholder="Phone *"
                onChange={e => this.setState({ phone: e.target.value })}
              />
            </div>
            <div className="form-group col-sm-6 col-md-6">
              <input
                type="text"
                className="form-control"
                name="eventtype"
                required
                placeholder="Event Type"
                onChange={e => this.setState({ eventtype: e.target.value })}
              />
            </div>
          </div>
          <div className="col-field col-sm-12 col-md-12 row">
            <div className="form-group col-sm-12 col-md-12">
              <DatePicker
                selected={this.state.thedate}
                onChange={e => this.setState({ thedate: e })}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-field col-sm-12 col-md-12">
            <div className="form-group">
              <textarea
                className="form-control"
                name="address"
                rows="3"
                required
                placeholder="Address *"
                onChange={e => this.setState({ address: e.target.value })}
              />
            </div>
          </div>
          <div className="col-field col-sm-12 col-md-12">
            <div className="form-group">
              <textarea
                className="form-control"
                name="detaisl"
                rows="5"
                required
                placeholder="Event Details: *"
                onChange={e => this.setState({ details: e.target.value })}
              />
            </div>
          </div>
          <div className="col-message col-field col-sm-12">
            <div className="form-group">
              {this.state.mailSending && (
                <div className="success-message">
                  {" "}
                  <i className="fa fa-check text-primary" /> sending eMail...{" "}
                </div>
              )}
              {this.state.mailSent && (
                <div className="success-message">
                  {" "}
                  <i className="fa fa-check text-primary" /> your email was sent{" "}
                </div>
              )}
              <div className="error-message">{this.state.mailError}</div>
            </div>
          </div>
        </div>
        <div className="form-submit text-right">
          <button type="submit" className="btn-shadow">
            Send <i className="icon-next" />
          </button>
        </div>
      </form>
    );
  }
}

export default ContactForm;
