import React from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import ContactForm from "./ContactForm";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Fill Out The Form Bellow
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <ContactForm />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="danger">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalContact() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <Row>
      <div className="email-button" onClick={() => setModalShow(true)}>
        <i className="fas fa-envelope-open-text" />
        <h4>Send me an email</h4>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Row>
  );
}

class ContactInfo extends React.Component {
  render() {
    return (
      <section>
        <Row className="contact-info">
          <Col lg={4} sm={12} className="info-cols">
            <a href="tel:5165224439">
              <i className="fas fa-mobile" />
            </a>
            <h4>(516) 522-4439</h4>
          </Col>
          <Col lg={4} sm={12} className="info-cols">
            <i className="fas fa-street-view" />
            <h4>Areas of Service</h4>
            <p>Long Island and New York City</p>
          </Col>
          <Col lg={4} sm={12} className="info-cols">
            <ModalContact />
          </Col>
        </Row>
      </section>
    );
  }
}

export default ContactInfo;
