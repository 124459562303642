import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { addLike, removeLike } from '../../actions/event';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';

const EventItem = ({
    addLike,
    removeLike,
    event: { _id, postphoto, title, when, location, likes, comments, date },
}) => {
    const evdate = <Moment format='MM/DD/YYYY'>{when}</Moment>;

    return (
        <div className='event-item'>
            <Timeline lineColor={'#000'}>
                <TimelineItem
                    dateText={evdate}
                    dateInnerStyle={{ background: '#B79A20', color: '#FFF' }}
                    bodyContainerStyle={{
                        background: '#f5f3f3',
                        padding: '20px',
                        borderRadius: '8px',
                        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                    }}
                >
                    <h4>
                        <Link to={`/events/${_id}`}>{title}</Link>
                    </h4>
                    <i
                        className='fas fa-location-arrow'
                        style={{ float: 'left' }}
                    ></i>{' '}
                    <h5>{location}</h5>
                    <div className='event-image'>
                        <Link to={`/events/${_id}`} className='event-image-all'>
                            <img
                                src={`${process.env.REACT_APP_CMS}${postphoto}`}
                                alt={title}
                            />
                        </Link>
                    </div>
                    <div className='card-text'>
                        <i
                            className='far fa-calendar-alt'
                            style={{ float: 'left', clear: 'both' }}
                        ></i>
                        <p className='full-date'>
                            <Moment format='MMMM DD, YYYY @ hh:mm A'>
                                {when}
                            </Moment>
                        </p>
                    </div>
                    <Fragment>
                        <button
                            onClick={(e) => addLike(_id)}
                            type='button'
                            className='post-like'
                        >
                            <i className='far fa-heart'></i>
                            <span className='counts'>{likes.length}</span>
                        </button>
                        <button
                            onClick={(e) => removeLike(_id)}
                            type='button'
                            className='post-like'
                        >
                            <i className='fas fa-heart-broken'></i>
                        </button>
                        <i className='fas fa-comment-alt'></i>
                        {comments.length > 0 && (
                            <span className='counts'>{comments.length}</span>
                        )}
                        <Link to={`/events/${_id}`} className='post-like'>
                            <span className='to-full-event'>READ MORE</span>
                        </Link>
                    </Fragment>
                </TimelineItem>
            </Timeline>
        </div>
    );
};

EventItem.propTypes = {
    event: PropTypes.object.isRequired,
    addLike: PropTypes.func.isRequired,
    removeLike: PropTypes.func.isRequired,
};

export default connect(null, { addLike, removeLike })(EventItem);
