import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import {
    addLike,
    removeLike,
    deleteEvent,
    getEvents,
} from '../../actions/event';
import { Row, Col } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyAAI_xfzsEco-NACZ3D4iXmawNcuSCVvXo');

const Location = () => (
    <div>
        <i
            className='fas fa-thumbtack'
            style={{
                fontSize: 60,
                color: '#fff',
                textShadow: '0.2rem 0.2rem 2rem rgba(0, 0, 0, 0.4)',
            }}
        ></i>
    </div>
);

const FullEvent = ({
    addLike,
    removeLike,
    getEvents,
    event: {
        _id,
        postphoto,
        title,
        when,
        location,
        description,
        name,
        avatar,
        user,
        likes,
        comments,
        date,
    },
}) => {
    const [locationState, setLocationState] = useState({});
    useEffect(() => {
        getEvents();

        const getLocation = async () => {
            const finalLocation = await Geocode.fromAddress(location);
            setLocationState({ ...finalLocation.results[0].geometry.location });
            console.log(finalLocation);
        };
        getLocation();
    }, [getEvents, location, setLocationState]);

    return (
        <div className='post-container'>
            <div className='full-post-image'>
                <img
                    src={`${process.env.REACT_APP_CMS}${postphoto}`}
                    alt={title}
                />
            </div>
            <div className='full-post-intro'>
                <div className='location-map'>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: 'AIzaSyCDoronmUKLLNU5PkhtG8Hmm95BClwSH5Y',
                        }}
                        center={{
                            lat: locationState.lat,
                            lng: locationState.lng,
                        }}
                        defaultZoom={13}
                    >
                        <Location
                            lat={locationState.lat}
                            lng={locationState.lng}
                        />
                    </GoogleMapReact>
                </div>

                <div className='user-in-posts'>
                    <Row>
                        <Col lg='4'>
                            <div className='posts-avatar'>
                                <Link to={`/profile/${user}`}>
                                    <img
                                        src={avatar}
                                        alt={name}
                                        style={{ width: '100%' }}
                                    />
                                </Link>
                            </div>
                        </Col>
                        <Col lg='8'>
                            <div className='posts-data'>
                                <p>Event By</p>
                                <h5>
                                    <Link to={`/profile/${user}`}>{name}</Link>
                                </h5>
                                <small className='text-muted'>
                                    <Moment format='MM/DD/YYYY'>{date}</Moment>
                                </small>
                                <div className='full-post-interaction'>
                                    <button
                                        onClick={(e) =>
                                            addLike(
                                                _id,
                                                window.location.reload(false)
                                            )
                                        }
                                        type='button'
                                        className='post-like'
                                    >
                                        <i className='far fa-heart'></i>
                                        <span className='counts'>
                                            {likes.length}
                                        </span>
                                    </button>
                                    <button
                                        onClick={(e) =>
                                            removeLike(
                                                _id,
                                                window.location.reload(false)
                                            )
                                        }
                                        type='button'
                                        className='post-like'
                                    >
                                        <i className='fas fa-heart-broken'></i>
                                    </button>
                                    <i className='fas fa-comment-alt'></i>
                                    {comments.length > 0 && (
                                        <span className='counts'>
                                            {comments.length}
                                        </span>
                                    )}
                                </div>
                                <Link to='/posts' className='dashboard-goback'>
                                    Back to Events
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='card-title'>{title}</div>

                <div className='card-text'>
                    <div className='event-info'>
                        <div className='info-blocks'>
                            <i
                                className='fas fa-location-arrow'
                                style={{ float: 'left' }}
                            ></i>
                            <h5 style={{ float: 'left', marginLeft: 10 }}>
                                {location}
                            </h5>
                        </div>
                        <div className='info-blocks'>
                            <i
                                className='far fa-calendar-alt'
                                style={{ float: 'left', marginLeft: 30 }}
                            ></i>
                            <h5 style={{ float: 'left', marginLeft: 10 }}>
                                <Moment format='MMMM DD, YYYY | hh:mm A'>
                                    {when}
                                </Moment>
                            </h5>
                        </div>
                    </div>
                    <div className='full-post-body'>
                        <h3>What is the event about</h3>
                        <div
                            dangerouslySetInnerHTML={{ __html: description }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

FullEvent.propTypes = {
    getEvents: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    addLike: PropTypes.func.isRequired,
    removeLike: PropTypes.func.isRequired,
    deleteEvent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    addLike,
    removeLike,
    deleteEvent,
    getEvents,
})(FullEvent);
