import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Player } from 'video-react';

import 'react-image-gallery/styles/css/image-gallery.css';

import missionsPict from '../../resources/images/Missions.jpg';
import fathersPitc from '../../resources/images/FathersDay.jpg';
import mothersPitc from '../../resources/images/MothersDay.jpg';

class VideoGallery extends React.Component {
    render() {
        return (
            <section>
                <div className='gallery-subtitles'>
                    <h2>Videos</h2>
                </div>
                <Row className='second-gallery'>
                    <Col className='video-gallery' lg={4} md={12} sm={12}>
                        <Player
                            poster={missionsPict}
                            playsInline
                            src='https://uploads.dorothyophals.com/uploads/missions-come-to-the-well.mp4'
                        />
                        <h4 className='video-description'>
                            Missions Convention
                        </h4>
                    </Col>
                    <Col className='video-gallery' lg={4} md={12} sm={12}>
                        <Player
                            poster={mothersPitc}
                            playsInline
                            src='https://uploads.dorothyophals.com/uploads/mothers-day.mp4'
                        />
                        <h4 className='video-description'>
                            Mother's Day Celebration
                        </h4>
                    </Col>
                    <Col className='video-gallery' lg={4} md={12} sm={12}>
                        <Player
                            poster={fathersPitc}
                            playsInline
                            src='https://uploads.dorothyophals.com/uploads/fathers-day.mp4'
                        />
                        <h4 className='video-description'>
                            Father's Day Celebration
                        </h4>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default VideoGallery;
