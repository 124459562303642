import React from 'react';
import { Row, Col } from 'react-bootstrap';

// Image
import writeSong from '../../resources/images/dorothy-ophals-compositor.jpg';

class ScrollToTop extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

class CustomMain extends React.Component {
  render() {
    return (
      <section>
        <ScrollToTop />
        <Row>
          <Col className='cs-first-column' sm={12}>
            <h2>Tell me your story</h2>
            <h1>I'll write you a song</h1>
          </Col>
        </Row>
        <Row>
          <Col className='cs-second-column' lg={5} md={6} sm={12}>
            <img src={writeSong} alt='Dorothy Ophals writing songs' />
          </Col>
          <Col className='cs-second-column' lg={7} md={6} sm={12}>
            <h4 style={{ marginTop: 40, marginBottom: 40 }}>
              One of my greatest passions in life is Song Writing, its in my
              blood. “My tongue is the pen of a ready writer “ Psalm 45:1
            </h4>
            <p>
              Sitting around the piano and writing songs was second nature to me
              all my life My first song I ever composed I was 17 years old and I
              wrote it for my high school graduation. At the end the song the
              last line I spoke was... “ Class of 79 dismissed . I already miss
              you”. My parents and friends later told me that the entire
              graduation class stood to there feet at the same time and through
              there caps in the air . That wasn’t suppose to happen. The
              principle came up and said oh well. That was a hard act to follow.
              I wrote all that to say to you that at a young age I knew it was
              something that I loved to do. Over the years I have written
              countless songs.
            </p>
            <p>
              In 2002 I got a song published with Christian singer / songwriter
              Kathy Troccoli on her Album “ The Heart of Me” Song entitled “
              You’re Still God”which the album was nominated for a Grammy. The
              first wedding song I ever wrote was for my husband and since that
              song I have been asked to sing it at countless weddings and have
              been asked to custom write Wedding song”s, for so many people. I
              have written for Baby baptism, Baby Dedications, Anniversary,
              Birthdays, Funerals, And Just songs to say I love You. Like I said
              before it’s a passion for me and it brings me so much joy to be
              able to put others peoples story to song. Please take a listen to
              some of my song clips which I made available for you. How does it
              work you might ask?
            </p>
          </Col>
        </Row>
        <Row style={{ background: 'hsla(0, 0%, 95%, 0.9)' }}>
          <Col sm={12} style={{ padding: 40 }}>
            <p style={{ margin: '10px 20px' }}>
              Well its simple: You place a call to me and we can discuss your
              story. If you live in New York or anywhere close we can meet for a
              session / If you live out of State we can have a session over the
              phone or face time. I want to hear your heart / what you want to
              say in the song/ You just tell me what are some things you want to
              say to that person in the song. I will help you with questions.
            </p>
            <p style={{ margin: '10px 20px' }}>
              We will have 2 sessions by phone and then I will get right to work
              to start creating your masterpiece. I will send you a form to fill
              out / what to expect from me and what I will expect from you. A
              deposit will be needed before I start the song and at the
              completion of the song you will pay in full. The song will be
              recorded in a studio with Piano and vocal - If you want a bigger
              production with string player or other musician that will be an
              extra fee which we can discuss after the song is written. There is
              a turnaround time of 1 month.
            </p>
          </Col>
          <Col sm={12} style={{ padding: 40 }}>
            <p style={{ margin: '10px 20px' }}>
              After final payment is received you will receive your song
              recorded and I will send the song by email/ cd/ google drive,
              whatever works for you. For an extra fee: If you are interested in
              me singing the song at your Wedding or event we can talk about
              that too.
            </p>
            <p style={{ margin: '10px 20px' }}>
              I write every song as if it was being written for me or those I
              love. I will put my heart into every word that is written. It
              would be an honor to hear your story so I could write you , Your
              song!.
            </p>
          </Col>
        </Row>
      </section>
    );
  }
}

export default CustomMain;
