import React from "react";
import { FadeTransform } from "react-animation-components";
import { Link } from "react-router-dom";

// Page elements
import SpinerLoader from "../SpinerLoader";
import LoginForm from "./LoginForm";
import Footer from "../Footer"

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  }

  render() {
    let contentLogin = this.state.loading ? (
      <SpinerLoader />
    ) : (
      <FadeTransform
        in
        transformProps={{
          exitTransform: "translateY(-50px)"
        }}
        fadeProps={{
          enterOpacity: 1
        }}
      >
        <section>
          <p>Are you a member?</p>
          <h1>Sign in here</h1>
        </section>
        <section style={{maxWidth: 800}}>
          <LoginForm />
        </section>
        <section style={{ textAlign: "center" }}>
          <p>
            Not a member yet? Create a user now
          </p>
          <button type="submit" className="btn-shadow">
            <Link to="/register" style={{ color: "#fff" }}>
              Register <i className="fas fa-chevron-right"></i>
            </Link>
          </button>
        </section>
        <Footer />
      </FadeTransform>
    );

    return <div>{contentLogin}</div>;
  }
}

export default Login;
