import React from 'react';
import { Row, Col } from 'react-bootstrap';

import nameLogo from '../../resources/images/dorothy-ophals-welcome-logo.png';

class ScrollToTop extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

class ContactMain extends React.Component {
  render() {
    return (
      <section>
        <ScrollToTop />
        <Row>
          <Col className='cs-first-column' sm={12}>
            <h2>Are you ready?</h2>
            <h1 style={{ fontSize: 60 }}>
              Contact{' '}
              <img
                className='logo-in-conact'
                src={nameLogo}
                alt='Contact Dorothy Ophals'
              />{' '}
              today
            </h1>
          </Col>
        </Row>
      </section>
    );
  }
}

export default ContactMain;
