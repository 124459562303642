import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { addLike, removeLike } from '../../actions/post';
import { Card, Row, Col } from 'react-bootstrap';

const PostItem = ({
    addLike,
    removeLike,

    post: {
        _id,
        postphoto,
        title,
        postintro,
        name,
        avatar,
        user,
        likes,
        comments,
        date,
    },
    showActions,
}) => {
    return (
        <Col lg='6' md='6' sm='12'>
            <Card>
                <Link to={`/posts/${_id}`} className='post-like'>
                    <Card.Img
                        variant='top'
                        src={`${process.env.REACT_APP_CMS}${postphoto}`}
                    />
                </Link>
                <Card.Body>
                    <div className='user-in-posts'>
                        <Row>
                            <Col lg='4'>
                                <div className='posts-avatar'>
                                    <img
                                        src={avatar}
                                        alt={name}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </Col>
                            <Col lg='8'>
                                <div className='posts-data'>
                                    <p>Post By</p>
                                    <h5>{name}</h5>
                                    <small className='text-muted'>
                                        <Moment format='MM/DD/YYYY'>
                                            {date}
                                        </Moment>
                                    </small>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='card-title'>
                        <Link to={`/posts/${_id}`} className='post-like'>
                            {title}
                        </Link>
                    </div>
                    <div className='card-text'>
                        <h5>{postintro}</h5>
                    </div>
                </Card.Body>
                <Card.Footer>
                    {showActions && (
                        <Fragment>
                            <button
                                onClick={(e) => addLike(_id)}
                                type='button'
                                className='post-like'
                            >
                                <i className='far fa-heart'></i>
                                <span className='counts'>{likes.length}</span>
                            </button>
                            <button
                                onClick={(e) => removeLike(_id)}
                                type='button'
                                className='post-like'
                            >
                                <i className='fas fa-heart-broken'></i>
                            </button>
                            <Link to={`/posts/${_id}`} className='post-like'>
                                <i className='fas fa-comment-alt'></i>
                                {comments.length > 0 && (
                                    <span className='counts'>
                                        {comments.length}
                                    </span>
                                )}
                                <span className='read'>READ</span>
                            </Link>
                        </Fragment>
                    )}
                </Card.Footer>
            </Card>
        </Col>
    );
};

PostItem.defaultProps = {
    showActions: true,
};

PostItem.propTypes = {
    post: PropTypes.object.isRequired,
    addLike: PropTypes.func.isRequired,
    removeLike: PropTypes.func.isRequired,
    showActions: PropTypes.bool,
};

export default connect(null, { addLike, removeLike })(PostItem);
