import React from "react";
import { GridSpinner } from "react-spinners-kit";

class SpinnerLoader extends React.Component {
  render() {
    return (
      <div className="loading-now">
        <div className="wave-spinner">
          <GridSpinner size={60} color="#b79a20" />
          <p>LOADING</p>
        </div>
      </div>
    );
  }
}

export default SpinnerLoader;
