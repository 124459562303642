import React from "react";
import { Row, Col } from "react-bootstrap";

class HomeContent extends React.Component {
  render() {
    return (
      <section>
        <Row className="home-hero">
          <Col>
            <div className="welcome-home">
              <h2 className="subtitle-home">Welcome to</h2>
              <h1 className="title-home">
                Dorothy <span>Jude</span> Ophals
              </h1>
              <p className="caption-home">Official Website</p>
            </div>
          </Col>
        </Row>
      </section>
    );
  }
}

export default HomeContent;
