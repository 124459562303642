import React from "react";
import { Row, Col } from "react-bootstrap";

class ScrollToTop extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

class BioMain extends React.Component {
  render() {
    return (
      <section>
        <ScrollToTop />
        <Row>
          <Col className="cs-first-column" sm={12}>
            <h2>Rooted in the Lord</h2>
            <h1>Dorothy Jude Ophals</h1>
            <p className="bio-quote">
              "To participate in and lead worship is such an awesome privilege.
              Together, we enter through the portal of praise into the holy
              courts of His presence. What unspeakable joy.”
            </p>
            <sub>-Dorothy Jude Ophals</sub>
          </Col>
        </Row>
      </section>
    );
  }
}

export default BioMain;
