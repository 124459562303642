import React from "react";
import { FadeTransform } from "react-animation-components";
import SpinnerLoader from "./SpinerLoader";

// Parts of MediaPage
import Footer from "./Footer";
import TopGallery from "./gallerry/TopGallery";
import MediaGallery from "./gallerry/MediaGallery";
import MediaJoyful from "./gallerry/MediaJoyful";
import VideoGallery from "./gallerry/VideoGallery";

class MediaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  }

  render() {
    let contentMedia = this.state.loading ? (
      <SpinnerLoader />
    ) : (
      <FadeTransform
        in
        transformProps={{
          exitTransform: "translateY(-50px)"
        }}
        fadeProps={{
          enterOpacity: 1
        }}
      >
        <TopGallery />
        <MediaJoyful />
        <VideoGallery />
        <MediaGallery />
        <Footer />
      </FadeTransform>
    );

    return <div>{contentMedia}</div>;
  }
}

export default MediaPage;
