import React from 'react';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Resources
import albumCover from '../../resources/images/its-all-about-love.jpg';

class HomeCards extends React.Component {
    render() {
        return (
            <section>
                <Row className='boxes'>
                    <Col lg={5} md={5} sm={12}>
                        <div className='boxes-1'>
                            <h3>Personalized Songs</h3>
                            <h5>
                                Tell me your story and I will write you a song
                            </h5>
                            <Button variant='warning'>
                                <Link
                                    style={{ color: '#000' }}
                                    to='/custom-songs-for-special-events'
                                >
                                    Learn More
                                </Link>
                            </Button>
                        </div>
                    </Col>
                    <Col lg={7} md={7} sm={12}>
                        <div className='boxes-2'>
                            <div className='home-album-left'>
                                <img src={albumCover} alt="Dorothy's Album" />

                                <a
                                    className='home-album-cta'
                                    href='https://music.apple.com/us/artist/dorothy-jude-ophals/1558189941'
                                    target='_blank'
                                    rel="noreferrer"
                                >
                                    Buy <i className='fab fa-apple' />
                                </a>
                                <a
                                    className='home-album-cta'
                                    href='https://open.spotify.com/artist/0j2JU3J2xOHApPhAeeVgUP'
                                    target='_blank'
                                    rel="noreferrer"
                                >
                                    Buy <i className='fab fa-spotify' />
                                </a>
                            </div>
                            <div className='home-album-rigth'>
                                <h3>It's All About Love</h3>
                                <ListGroup variant='flush'>
                                    <ListGroup.Item>This Ring</ListGroup.Item>
                                    <ListGroup.Item>Believe</ListGroup.Item>
                                    <ListGroup.Item>
                                        Eres Mi Amor
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        I Love You More
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                            <Link
                                className='home-album-cta all-button'
                                to='/songs-and-albums'
                            >
                                All Albums
                            </Link>
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default HomeCards;
