import React from 'react';
import { Row, Col } from 'react-bootstrap';

const features = [
  {
    index: 0,
    title: 'Professionally Written',
    icon: '/images/professional-witten-and-composited.png',
    explication:
      'I have years of experience as singer and songwriter, I will write your story and create the song that will make it even greater.',
  },
  {
    index: 1,
    title: 'Studio Recording',
    icon: '/images/studio-recording.png',
    explication:
      'When I finish with the soundtrack of that precious moment of your life, I will record it in a professional studio.',
  },
  {
    index: 2,
    title: 'Optional Performance',
    icon: '/images/optional-performance.png',
    explication:
      'Would you like your story to be heard by others, I can perform it live for you and your loved ones at your at your special event.',
  },
  {
    index: 3,
    title: 'Keep Your Treasure',
    icon: '/images/format-deliver.png',
    explication:
      "You'll receive your song in different formats, so you can play it on your phone, computer or any audio syste, whenever you want.",
  },
];

class CustomFeatures extends React.Component {
  render() {
    return (
      <section style={{ backgroundColor: 'rgba(183, 154, 32, 0.05)' }}>
        <Row>
          {features.map((feature, index) => (
            <Col lg={3} md={6} sm={12} key={index} className='features'>
              <img src={feature.icon} alt={feature.title} />
              <h4 className='features-title'>{feature.title}</h4>
              <p className='features-content'>{feature.explication}</p>
            </Col>
          ))}
        </Row>
      </section>
    );
  }
}

export default CustomFeatures;
