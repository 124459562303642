import React from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';

import 'react-image-gallery/styles/css/image-gallery.css';

// Images
import joyfulOne from '../../resources/gallery/joyful-singers-one.JPG';
import joyfulTwo from '../../resources/gallery/joyful-singers-two.JPG';
import joyfulThree from '../../resources/gallery/joyful-singers-missions-one.JPG';
import joyfulFour from '../../resources/gallery/joyful-singers-missions-two.JPG';
import joyfulFive from '../../resources/gallery/joyful-singers-missions-three.JPG';
import joyfulSix from '../../resources/gallery/joyful-singers-missions-four.JPG';

class MediaJoyful extends React.Component {
  render() {
    return (
      <section>
        <Row className='gallery-intro'>
          <Col lg={5} md={12} sm={12}>
            <h3 className='joyful-title'>Joyful Singers</h3>
            <h4>A children’s choir directed by Dorothy</h4>
            <p>
              <a
                href='https://sgtchurch.org'
                target='_blank'
                rel='noopener noreferrer'
              >
                Smithtown Gospel Tabernacle
              </a>
            </p>
            <p style={{ paddingRight: 30 }}>
              This is a choir composed by 60-70 kids from ages 6 - 12, sons and
              daughters of Smithtwon Gospel Tabernacle members. They performed
              songs written by Dorothy and are directed by herself in events
              like Mother's Day, Father's Day, Christmas, Thanksgiving, etc.
            </p>
            <p style={{ paddingRight: 30 }}>
              If you want your children to be part of this amazing choir I
              invite to you come to Smithtown Gospel Tabernacle and inquire more
              about the program. Dorothy will make sure your kids will have so
              much fun worshiping the Lord.
            </p>
            <p style={{ paddingRight: 30 }}>
              Scroll down to see videos of live events with the Joyful Singers
              at Smithtown Gospel Tabernacle. You can see more pictures too of
              the fellowship the children have with me and with each other,
              because this is more than just a choir, it's about teaching them
              the gift we have when we live for the Lord.
            </p>
          </Col>
          <Col lg={7} md={12} sm={12}>
            <Carousel>
              <Carousel.Item>
                <img
                  className='d-block w-100'
                  src={joyfulOne}
                  alt='First slide'
                />
                <Carousel.Caption>
                  <h3>Dorothy J Ophals</h3>
                  <p>Joyful Singers Director</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100'
                  src={joyfulTwo}
                  alt='Third slide'
                />

                <Carousel.Caption>
                  <h3>Christmas Concert</h3>
                  <p>Smithtown Gospel Tabernacle</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100'
                  src={joyfulThree}
                  alt='Third slide'
                />

                <Carousel.Caption>
                  <h3>Praying for Missions</h3>
                  <p>Smithtown Gospel Tabernacle</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100'
                  src={joyfulFour}
                  alt='Third slide'
                />

                <Carousel.Caption>
                  <h3>Missions Days</h3>
                  <p>Preparing for Missions Convention</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100'
                  src={joyfulFive}
                  alt='Third slide'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100'
                  src={joyfulSix}
                  alt='Third slide'
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </section>
    );
  }
}

export default MediaJoyful;
