import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import singingPhoto from '../../resources/images/dorothy-ophals-singing.jpg';

class CustomEvents extends React.Component {
  render() {
    return (
      <section>
        <Row style={{ marginTop: 40 }}>
          <Col lg={8} md={7} sm={6}>
            <Row>
              <Col className='sing-title'>
                <h3>Let me write it and sing it</h3>
                <h2>For You</h2>
              </Col>
            </Row>
            <Row style={{ paddingTop: 30, paddingLeft: 20 }}>
              <Col className='events-list'>
                <p>Weddings</p>
                <p>Anniversaries</p>
                <p>Birthdays</p>
                <p>Graduations</p>
                <p>Monther's & Father's Day</p>
              </Col>
              <Col className='events-list'>
                <p>Just to say "I Love You"</p>
                <p>Christenings & Dedications</p>
                <p>Theme Songs</p>
                <p>In memory of a loved one</p>
              </Col>
            </Row>
            <Row>
              <Col
                className='contact-me'
                style={{ paddingTop: 30, paddingLeft: 20 }}
              >
                <Button className='contact-me-b' variant='warning'>
                  <Link className='contact-me-btn' to='/contact-dorothy-ophals'>
                    Contact Me Now To Start
                  </Link>
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg={4} md={5} sm={6}>
            <img
              className='studio-dot'
              src={singingPhoto}
              alt='Dorothy Ophals in Studio'
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default CustomEvents;
