import React from "react";
import { FadeTransform } from "react-animation-components";
import SpinnerLoader from "./SpinerLoader";

// Parts of HomePage
import Footer from "./Footer";
import HomeContent from "./homeparts/HomeContent";
import HomeCards from "./homeparts/HomeCards";
import MainVideo from "./homeparts/MainVideo";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  }

  render() {
    let contentHome = this.state.loading ? (
      <SpinnerLoader />
    ) : (
      <FadeTransform
        in
        transformProps={{
          exitTransform: "translateY(-50px)"
        }}
        fadeProps={{
          enterOpacity: 1
        }}
      >
        <HomeContent />
        <MainVideo />
        <HomeCards />
        <Footer />
      </FadeTransform>
    );

    return <div>{contentHome}</div>;
  }
}

export default HomePage;
