import React, { Fragment, useEffect } from 'react';
import { Navbar, Nav, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../actions/auth';
import { getCurrentProfile } from '../actions/profile';

// Components
import PlayerTop from './PlayerTop';

// Logo Image
import logo from '../resources/images/dorothy-ophals-logo.png';

function closeMenu() {
  var themaintoggle = document.getElementsByClassName('navbar-toggler');
  var themenuitem = document.getElementById('responsive-navbar-nav');
  themenuitem.classList.remove('show');
  if (themenuitem.classList.remove('show') === true) {
    themaintoggle.classList.add('collapsed');
  }
}

const NavigationBar = ({
  auth: { isAuthenticated, user, loading },
  logout,
  getCurrentProfile,
}) => {
  const authLinks = (
    <Fragment>
      <Nav className='user-menu'>
        <div className='menu-items'>
          Welcome, {user && user.name}
          <NavLink to='#!' activeClassName='active' onClick={logout}>
            <i className='fas fa-sign-out-alt'></i>
          </NavLink>
        </div>
      </Nav>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      <Nav className='user-menu'>
        <div className='menu-items'>
          <NavLink to='/login' activeClassName='active' onClick={closeMenu}>
            <i className='fas fa-sign-in-alt'></i>
          </NavLink>
        </div>
        <div className='menu-items'>
          <NavLink to='/register' activeClassName='active' onClick={closeMenu}>
            <i className='fas fa-user-plus'></i>
          </NavLink>
        </div>
      </Nav>
    </Fragment>
  );

  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);
  return (
    <Row>
      <Col lg={2} md={6} sm={12} className='col-logo'>
        <div className='logo-class'>
          <a href='/'>
            <img src={logo} alt='Dorothy Ophals' />
          </a>
        </div>
      </Col>
      <Col lg={6} md={6} sm={12} className='col-menu'>
        <Navbar collapseOnSelect expand='lg' variant='light'>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='mr-auto'>
              <div className='menu-items'>
                <NavLink
                  to='/dorothy-ophals-bio'
                  activeClassName='active'
                  onClick={closeMenu}
                >
                  Bio
                </NavLink>
              </div>
              <div className='menu-items'>
                <NavLink
                  to='/contact-dorothy-ophals'
                  activeClassName='active'
                  onClick={closeMenu}
                >
                  Contact Me
                </NavLink>
              </div>
              <div className='menu-items'>
                <NavLink
                  to='/image-and-video-gallery'
                  activeClassName='active'
                  onClick={closeMenu}
                >
                  Gallery
                </NavLink>
              </div>
              <div className='menu-items'>
                <NavLink
                  to='/songs-and-albums'
                  activeClassName='active'
                  onClick={closeMenu}
                >
                  Songs
                </NavLink>
              </div>
              <div className='menu-items'>
                <NavLink
                  to='/posts'
                  activeClassName='active'
                  onClick={closeMenu}
                >
                  Happenings
                </NavLink>
              </div>
              <div className='menu-items'>
                <NavLink
                  to='/music-lessons'
                  activeClassName='active'
                  onClick={closeMenu}
                >
                  Lessons
                </NavLink>
              </div>
              <div className='menu-items'>
                <NavLink
                  to='/custom-songs-for-special-events'
                  activeClassName='active'
                  onClick={closeMenu}
                >
                  Custom Songs
                </NavLink>
              </div>
            </Nav>
          </Navbar.Collapse>
          {!loading && (
            <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
          )}
        </Navbar>
      </Col>
      <Col lg={4} md={12} sm={12} className='col-player'>
        <PlayerTop />
      </Col>
    </Row>
  );
};

NavigationBar.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { logout, getCurrentProfile })(
  NavigationBar
);
