import React from 'react';
import ReactPlayer from 'react-player';
import { Row, Col } from 'react-bootstrap';

import Duration from '../Duration';

import './FullAlbum.css';

class TheAlbum extends React.Component {

    state = {
        url: null,
        pip: false,
        playing: true,
        controls: false,
        light: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
    };
    load = (url, label) => {
        this.setState({
            url,
            label,
            played: 0,
            loaded: 0,
        });
    };
    playPause = () => {
        this.setState({ playing: !this.state.playing });
    };
    stop = () => {
        this.setState({ url: null, playing: false });
    };
    toggleControls = () => {
        const url = this.state.url;
        const label = this.state.label;
        this.setState(
            {
                controls: !this.state.controls,
                url: null,
                label: null,
            },
            () => this.load(url, label)
        );
    };
    toggleLight = () => {
        this.setState({ light: !this.state.light });
    };
    toggleLoop = () => {
        this.setState({ loop: !this.state.loop });
    };
    setPlaybackRate = (e) => {
        this.setState({ playbackRate: parseFloat(e.target.value) });
    };
    togglePIP = () => {
        this.setState({ pip: !this.state.pip });
    };
    onPlay = () => {
        this.setState({ playing: true });
    };
    onPause = () => {
        this.setState({ playing: false });
    };
    onSeekMouseDown = (e) => {
        this.setState({ seeking: true });
    };
    onSeekChange = (e) => {
        this.setState({ played: parseFloat(e.target.value) });
    };
    onSeekMouseUp = (e) => {
        this.setState({ seeking: false });
        this.player.seekTo(parseFloat(e.target.value));
    };
    onProgress = (state) => {
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state);
        }
    };
    onEnded = () => {
        this.setState({ playing: this.state.loop });
    };
    onDuration = (duration) => {
        this.setState({ duration });
    };
    renderLoadButton = (url, label) => {
        return (
            <div>
                <a
                    href='#/'
                    onClick={() => this.load(url, label)}
                    className='songs-play-full'
                >
                    Play <i className='fas fa-play-circle' />
                    <p>{label}</p>
                </a>
            </div>
        );
    };
    ref = (player) => {
        this.player = player;
    };

    render() {
        const {
            url,
            label,
            playing,
            duration,
            controls,
            light,
            loop,
            played,
            playbackRate,
        } = this.state;

        return (
            <Row className='album-container'>
                <Col className='album-left-column' lg={4} md={3} sm={12}>
                    <img
                        className='cover-left'
                        src={this.props.info.image}
                        alt={this.props.info.title}
                    />
                    <div id='playerContainer'>
                        <div id='controlContainer'>
                            <ReactPlayer
                                ref={this.ref}
                                className='react-player'
                                width='100%'
                                height='100%'
                                url={url}
                                label={label}
                                playing={playing}
                                controls={controls}
                                light={light}
                                loop={loop}
                                playbackRate={playbackRate}
                                onReady={() => console.log('onReady')}
                                onStart={() => console.log('onStart')}
                                onPlay={this.onPlay}
                                onPause={this.onPause}
                                onBuffer={() => console.log('onBuffer')}
                                onSeek={(e) => console.log('onSeek', e)}
                                onEnded={this.onEnded}
                                onError={(e) => console.log('onError', e)}
                                onProgress={this.onProgress}
                                onDuration={this.onDuration}
                            />
                            {this.props.info.title.startsWith('Coming') ? (
                                <p
                                    className='now-playing'
                                    style={{ fontSize: 20, marginBottom: 50 }}
                                >
                                    Coming This Fall
                                </p>
                            ) : (
                                <div className='controls'>
                                    <div>
                                        {label ? (
                                            <p className='now-playing'>
                                                Now Playing:
                                                <br /> <span>{label}</span>
                                            </p>
                                        ) : (
                                            <p className='now-playing'>
                                                Pick a song
                                            </p>
                                        )}
                                    </div>
                                    <div className='controls-firstline clearfix'>
                                        <div className='player-icon'>
                                            <a
                                                href='#/'
                                                data-attr='playPauseAudio'
                                                onClick={this.playPause}
                                            >
                                                {playing ? (
                                                    <div className='pause' />
                                                ) : (
                                                    <div className='play' />
                                                )}
                                            </a>
                                        </div>
                                        <div className='player-progress'>
                                            <div className='progress'>
                                                <input
                                                    type='range'
                                                    min={0}
                                                    max={1}
                                                    step='any'
                                                    value={played}
                                                    onMouseDown={
                                                        this.onSeekMouseDown
                                                    }
                                                    onChange={this.onSeekChange}
                                                    onMouseUp={
                                                        this.onSeekMouseUp
                                                    }
                                                    className='seekableTrack'
                                                />
                                            </div>
                                        </div>
                                        <div className='player-time'>
                                            <span
                                                data-attr='timer'
                                                className='audioTime'
                                            >
                                                <Duration
                                                    seconds={duration * played}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
                <Col className='album-right-column' lg={8} md={9} sm={12}>
                    <div id='listContainer' className='playlistContainer'>
                        <h3>
                            Album: <span>{this.props.info.title}</span>
                        </h3>
                        {!this.props.info.title.startsWith('Coming') && (
                            <ul id='playListContainer'>
                                {this.props.songs.map((sg) => (
                                    <li key={sg.id} className='activeAudio'>
                                        {this.renderLoadButton(
                                            sg.localclip,
                                            sg.songname
                                        )}
                                        <div className='stores-buttons'>
                                            <a
                                                href={sg.apple}
                                                target='_blank'
                                                rel='noreferrer'
                                                className='store-one'
                                            >
                                                <i className='fab fa-apple' />
                                            </a>
                                            <a
                                                href={sg.spotify}
                                                target='_blank'
                                                rel='noreferrer'
                                                className='store-one'
                                            >
                                                <i className='fab fa-spotify' />
                                            </a>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </Col>
            </Row>
        );
    }
}

export default TheAlbum;
