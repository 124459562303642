import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';

import pointerCard from '../../resources/images/contact-pointer.jpg';

const Location = ({ text }) => (
    <Row className='block-on-map'>
        <Col>
            <img src={pointerCard} alt='Dorothy Ophals Business Card' />
        </Col>
    </Row>
);

class ContactMap extends React.Component {
    static defaultProps = {
        center: {
            lat: 40.7263441,
            lng: -73.4364847,
        },
        zoom: 13,
    };

    render() {
        return (
            <section style={{ height: '545px', marginTop: '-70px' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: 'AIzaSyAAI_xfzsEco-NACZ3D4iXmawNcuSCVvXo',
                    }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    <Location lat={40.7263441} lng={-73.4364847} />
                </GoogleMapReact>
            </section>
        );
    }
}

export default ContactMap;
