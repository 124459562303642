import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SpinerLoader from "../SpinerLoader";
import { getPosts } from "../../actions/post";
import { getEvents } from "../../actions/event";
import { Row, Col } from "react-bootstrap";
import PostItem from "./PostItem";
import EventItem from "../events/EventItem";
import Footer from "../Footer";

const Posts = ({
  getPosts,
  post: { posts, loading },
  getEvents,
  event: { events }
}) => {
  useEffect(() => {
    getPosts();
    getEvents();
  }, [getPosts, getEvents]);

  return loading ? (
    <SpinerLoader />
  ) : (
    <Fragment>
      <section>
        <Row className="title-happenings">
          <Col>
            <h1>Happenings</h1>
            <p>What Dorothy is writing and her next events.</p>
          </Col>
        </Row>
        <Row className="all-happenings">
          <Col lg="4" md="12" sm="12">
            <h3>Next Events</h3>
            <div className="all-events">
              {events.map(event => (
                <EventItem key={event._id} event={event} />
              ))}
            </div>
          </Col>
          <Col lg="8" md="12" sm="12">
            <h3>Blog</h3>
            {posts.map(post => (
              <PostItem key={post._id} post={post} />
            ))}
          </Col>
        </Row>
      </section>
      <Footer />
    </Fragment>
  );
};

Posts.propTypes = {
  getPosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  getEvents: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  post: state.post,
  event: state.event
});

export default connect(mapStateToProps, { getPosts, getEvents })(Posts);
