import React from 'react';
import Icon from 'react-icons-kit';
import { facebook } from 'react-icons-kit/fa/facebook';
import { instagram } from 'react-icons-kit/fa/instagram';
import { Row, Col, Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

// Images
import smallLogo from '../resources/images/djo-logo@2x.png';

class Footer extends React.Component {
    render() {
        return (
            <footer className='footer-block'>
                <Row>
                    <Col lg={2} md={2} sm={12}>
                        <img src={smallLogo} alt='Dorothy Jude Ophals' />
                    </Col>
                    <Col lg={8} md={8} sm={12}>
                        <h5 className='footer-closing'>
                            <span>Thanks For Visiting My Website</span>
                            <br />
                            Dorothy Jude Ophals
                        </h5>
                        <p>All Rights Reserved © 2019</p>
                    </Col>
                    <Col lg={2} md={2} sm={12}>
                        <div className='social-footer'>
                            <a
                                href='https://www.facebook.com/dorothy.ophals/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <Icon
                                    className='social-icons'
                                    size={24}
                                    icon={facebook}
                                />
                            </a>
                            <a
                                href='https://instagram.com/dorothyophals?igshid=1uwp96poc1usl'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <Icon
                                    className='social-icons'
                                    size={24}
                                    icon={instagram}
                                />
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: 50, zIndex: 100 }}>
                    <Navbar
                        collapseOnSelect
                        expand='lg'
                        bg='light'
                        variant='light'
                        style={{ width: '100%' }}
                    >
                        <Navbar.Toggle aria-controls='responsive-navbar-nav2' />
                        <Navbar.Collapse
                            className='footer-navbar'
                            id='responsive-navbar-nav2'
                        >
                            <Nav className='mr-auto-footer'>
                                <div className='menu-items-footer'>
                                    <NavLink to='/' activeClassName='active'>
                                        Home
                                    </NavLink>
                                </div>
                                <div className='menu-items-footer'>
                                    <NavLink
                                        to='/dorothy-ophals-bio'
                                        activeClassName='active'
                                    >
                                        Bio
                                    </NavLink>
                                </div>
                                <div className='menu-items-footer'>
                                    <NavLink
                                        to='/contact-dorothy-ophals'
                                        activeClassName='active'
                                    >
                                        Contact Me
                                    </NavLink>
                                </div>
                                <div className='menu-items-footer'>
                                    <NavLink
                                        to='/image-and-video-gallery'
                                        activeClassName='active'
                                    >
                                        Gallery
                                    </NavLink>
                                </div>
                                <div className='menu-items-footer'>
                                    <NavLink
                                        to='/songs-and-albums'
                                        activeClassName='active'
                                    >
                                        Songs
                                    </NavLink>
                                </div>
                                <div className='menu-items-footer'>
                                    <NavLink
                                        to='/happenings-thoughts-and-events'
                                        activeClassName='active'
                                    >
                                        Happenings
                                    </NavLink>
                                </div>
                                <div className='menu-items-footer'>
                                    <NavLink
                                        to='/music-lessons'
                                        activeClassName='active'
                                    >
                                        Lessons
                                    </NavLink>
                                </div>
                                <div className='menu-items-footer'>
                                    <NavLink
                                        to='/custom-songs-for-special-events'
                                        activeClassName='active'
                                    >
                                        Custom Songs
                                    </NavLink>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Row>
                <Row style={{ paddingTop: 50 }}>
                    <Col lg={12} sm={12}>
                        <p>
                            Design and Dev by{' '}
                            <a
                                href='https://plitz7.com'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Plitz7
                            </a>
                        </p>
                    </Col>
                </Row>
            </footer>
        );
    }
}

export default Footer;
