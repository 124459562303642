import React from "react";
import { Row, Col } from "react-bootstrap";

class ScrollToTop extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

class ContactMain extends React.Component {
  render() {
    return (
      <section>
        <ScrollToTop />
        <Row>
          <Col className="cs-first-column" sm={12}>
            <h2>Photo and Video Gallery</h2>
            <h1 style={{ fontSize: 60 }}>See Dorothy in action</h1>
          </Col>
        </Row>
      </section>
    );
  }
}

export default ContactMain;
