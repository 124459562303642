import React from 'react';
import { Row, Col } from 'react-bootstrap';

// Image
import dorothyBio from '../../resources/images/dorothy-j-ophals-bio.jpg';

class BioPicture extends React.Component {
  render() {
    return (
      <section>
        <Row className='biography'>
          <Col lg={6} md={4} sm={12} className='left-bio'>
            <img src={dorothyBio} alt='Dorothy Ophals bio' />
          </Col>
          <Col lg={6} md={8} sm={12} className='right-bio'>
            <p>
              Dorothy Jude Opals has been expressing herself through song for
              three decades. Born into a musical family, she was exposed to a
              wide range of genres at a very young age. It was not until she had
              a life changing encounter with Jesus Christ that Dorothy became
              deeply committed to using her gifts in order to have a more
              meaningful and lasting impact upon both the churched and
              unchurched. Her hunger for the Word and her desire to bring glory
              to God fuels her passion as a songwriter, worship leader, and
              choir director. Dorothy is involved in the music ministry of her
              home church in New York; Smithtown Tabernacle.
            </p>
            <p>
              Dorothy has a great resolve to see the Church universal grow in
              knowledge and passion concerning the things of God. She has
              devoted her life to searching Him out through His Word, prayer and
              fasting, and asking Him to reveal Himself through every facet of
              her platform. Dorothy has written a wide range of songs that
              include worship, modern hymns, children’s songs, as well as raw,
              thought provoking solo pieces. She draws upon her own hard earned
              life lessons and the ability to poignantly communicate the
              struggles and victories common to many as well as the majesty and
              grandeur of the Savior.
            </p>
            <p>
              Dorothy is a highly sought after musical guest and worship leader
              at conferences, mission trips, retreats and special events.
              Dorothy says, “First and foremost I am a woman who is
              wholeheartedly devoted to Jesus Christ. I serve Him through loving
              my family, serving the church, and pointing to the only One worthy
              of all honor and praise.” Dorothy has a genuine heart to see
              worship change the course of lives, churches and the nations.
              Dorothy and her husband Rich live in New York are are parents to a
              grown son named Richie.
            </p>
          </Col>
        </Row>
      </section>
    );
  }
}

export default BioPicture;
