import React from "react";
import { FadeTransform } from "react-animation-components";
import SpinnerLoader from "./SpinerLoader";

// Parts of BioPage
import Footer from "./Footer";
import BioMain from "./bio/BioMain";
import BioPicture from "./bio/BioPicture";
import BioHistory from "./bio/BioHistory";
import HomeTimeline from "./bio/HomeTimeline";

class BioPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  }

  render() {
    let contentBio = this.state.loading ? (
      <SpinnerLoader />
    ) : (
      <FadeTransform
        in
        transformProps={{
          exitTransform: "translateY(-50px)"
        }}
        fadeProps={{
          enterOpacity: 1
        }}
      >
        <BioMain />
        <BioPicture />
        <BioHistory />
        <HomeTimeline />
        <Footer />
      </FadeTransform>
    );

    return <div>{contentBio}</div>;
  }
}

export default BioPage;
