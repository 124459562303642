import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { addLike, removeLike, deletePost, getPosts } from '../../actions/post';
import { Row, Col } from 'react-bootstrap';

import PlaceHolder from '../../resources/images/placeholder.jpg';

const FullPost = ({
    addLike,
    removeLike,
    deletePost,
    getPosts,
    post: {
        _id,
        postphoto,
        title,
        postintro,
        text,
        name,
        avatar,
        user,
        likes,
        comments,
        date,
    },
}) => {
    useEffect(() => {
        getPosts();
    }, [getPosts]);

    return (
        <div className='post-container'>
            <div className='full-post-image'>
                {postphoto === '' ? (
                    <img
                        src={PlaceHolder}
                        alt='Dorothy Ophals logo placeholder'
                    />
                ) : (
                    <img
                        src={`${process.env.REACT_APP_CMS}${postphoto}`}
                        alt={title}
                    />
                )}
            </div>
            <div className='full-post-intro'>
                <div className='user-in-posts'>
                    <Row>
                        <Col lg='4'>
                            <div className='posts-avatar'>
                                <Link to={`/profile/${user}`}>
                                    <img
                                        src={avatar}
                                        alt={name}
                                        style={{ width: '100%' }}
                                    />
                                </Link>
                            </div>
                        </Col>
                        <Col lg='8'>
                            <div className='posts-data'>
                                <p>Post By</p>
                                <h5>
                                    <Link to={`/profile/${user}`}>{name}</Link>
                                </h5>
                                <small className='text-muted'>
                                    <Moment format='MM/DD/YYYY'>{date}</Moment>
                                </small>
                                <div className='full-post-interaction'>
                                    <button
                                        onClick={(e) =>
                                            addLike(
                                                _id,
                                                window.location.reload(false)
                                            )
                                        }
                                        type='button'
                                        className='post-like'
                                    >
                                        <i className='far fa-heart'></i>
                                        <span className='counts'>
                                            {likes.length}
                                        </span>
                                    </button>
                                    <button
                                        onClick={(e) =>
                                            removeLike(
                                                _id,
                                                window.location.reload(false)
                                            )
                                        }
                                        type='button'
                                        className='post-like'
                                    >
                                        <i className='fas fa-heart-broken'></i>
                                    </button>
                                    <i className='fas fa-comment-alt'></i>
                                    {comments.length > 0 && (
                                        <span className='counts'>
                                            {comments.length}
                                        </span>
                                    )}
                                </div>
                                <Link to='/posts' className='dashboard-goback'>
                                    Back to Posts
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='card-title'>{title}</div>
                <div className='card-text'>
                    <h5>{postintro}</h5>
                    <div className='full-post-body'>
                        <div dangerouslySetInnerHTML={{ __html: text }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

FullPost.propTypes = {
    getPosts: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
    addLike: PropTypes.func.isRequired,
    removeLike: PropTypes.func.isRequired,
    deletePost: PropTypes.func.isRequired,
};

export default connect(null, {
    addLike,
    removeLike,
    deletePost,
    getPosts,
})(FullPost);
