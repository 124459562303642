import React from "react";
import { FadeTransform } from "react-animation-components";
import SpinerLoader from "../SpinerLoader";
import {Link} from "react-router-dom";

// Parts
import RegisterForm from "./RegisterForm";
import Footer from "../Footer";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  }

  render() {
    let contentRegister = this.state.loading ? (
      <SpinerLoader />
    ) : (
      <FadeTransform
        in
        transformProps={{
          exitTransform: "translateY(-50px)"
        }}
        fadeProps={{
          enterOpacity: 1
        }}
      >
        <section>
          <p>By registering you will be able to like and comment on posts, at the same time, you will be part of Dorothy's newletters.</p>
          <h1>Register</h1>
        </section>
        <section style={{maxWidth: 800}}>
          <RegisterForm />
        </section>
        <section style={{ textAlign: "center" }}>
          <p>
            Do you have an account?
          </p>
          <button type="submit" className="btn-shadow">
            <Link to="/login" style={{ color: "#fff" }}>
              Sign In <i className="fas fa-chevron-right"></i>
            </Link>
          </button>
        </section>
        <Footer />
      </FadeTransform>
    );

    return <div>{contentRegister}</div>;
  }
}

export default Register;
