import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import Duration from './Duration';

// Images in the homepage
import trackCover from '../resources/images/album-cover.jpg';

require('./PlayerTop.css');

class PlayerTop extends React.Component {
  state = {
    url: null,
    pip: false,
    playing: true,
    controls: false,
    light: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
  };
  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
    });
  };
  playPause = () => {
    this.setState({ playing: !this.state.playing });
  };
  stop = () => {
    this.setState({ url: null, playing: false });
  };
  toggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url)
    );
  };
  toggleLight = () => {
    this.setState({ light: !this.state.light });
  };
  toggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };
  setPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };
  togglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };
  onPlay = () => {
    this.setState({ playing: true });
  };
  onPause = () => {
    this.setState({ playing: false });
  };
  onSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };
  onSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };
  onSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };
  onProgress = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };
  onEnded = () => {
    this.setState({ playing: this.state.loop });
  };
  onDuration = (duration) => {
    this.setState({ duration });
  };
  renderLoadButton = (url, label) => {
    return (
      <div>
        <a href='#/' onClick={() => this.load(url)}>
          <img src={trackCover} width='90' alt='' />
          <h5>Believe</h5>
        </a>
        <a href='#/' onClick={() => this.load(url)} className='songs-play'>
          Play This Song
        </a>
        <Link to='/songs-and-albums' className='songs-play'>
          Song Clips
        </Link>
      </div>
    );
  };
  ref = (player) => {
    this.player = player;
  };

  render() {
    const {
      url,
      playing,
      controls,
      duration,
      light,
      loop,
      played,
      playbackRate,
    } = this.state;

    return (
      <div className='containerPlayer'>
        <div id='listContainer' className='playlistContainer'>
          <ul id='playListContainer'>
            <li className='activeAudio'>
              {this.renderLoadButton(
                '/audio/believe-by-dorothy-ophals-2019.mp3',
                'mp3'
              )}
            </li>
          </ul>
        </div>
        <div id='playerContainer'>
          <div id='controlContainer'>
            <ReactPlayer
              ref={this.ref}
              className='react-player'
              width='100%'
              height='100%'
              url={url}
              playing={playing}
              controls={controls}
              light={light}
              loop={loop}
              playbackRate={playbackRate}
              onReady={() => console.log('onReady')}
              onStart={() => console.log('onStart')}
              onPlay={this.onPlay}
              onPause={this.onPause}
              onBuffer={() => console.log('onBuffer')}
              onSeek={(e) => console.log('onSeek', e)}
              onEnded={this.onEnded}
              onError={(e) => console.log('onError', e)}
              onProgress={this.onProgress}
              onDuration={this.onDuration}
            />
            <div className='controls'>
              <div className='controls-firstline clearfix'>
                <div className='player-icon'>
                  <a
                    href='#/'
                    data-attr='playPauseAudio'
                    onClick={this.playPause}
                  >
                    {playing ? (
                      <div className='pause' />
                    ) : (
                      <div className='play' />
                    )}
                  </a>
                </div>
                <div className='player-progress'>
                  <div className='progress'>
                    <input
                      type='range'
                      min={0}
                      max={1}
                      step='any'
                      value={played}
                      onMouseDown={this.onSeekMouseDown}
                      onChange={this.onSeekChange}
                      onMouseUp={this.onSeekMouseUp}
                      className='seekableTrack'
                    />
                  </div>
                </div>
                <div className='player-time'>
                  <span data-attr='timer' className='audioTime'>
                    <Duration seconds={duration * played} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlayerTop;
